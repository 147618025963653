import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LinkedInIcon from '@mui/icons-material/LinkedIn';

function WCAG() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'WCAG 2.1 AA Compliant'}
      {/* {new Date().getFullYear()} */}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      aria-label="Footer section with site information and links"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 2, sm: 4 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 0, sm: 2 },
          width: '100%',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="" aria-label="Read our Privacy Policy">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="" aria-label="Read our Terms of Service">
            Terms of Service
          </Link>
          <WCAG />
        </div>

        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/in/kannangovindasamy/"
            aria-label="Visit our LinkedIn profile"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container >
  );
}
