import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CertBuddy_Landing_Vid from '../videos/Compressed_Landing_Vid.mp4';

const texts = [
    "An end to end solution for managing the certification process in the transportation and consumer product industry. Striking a balance between power of AI and human experience. Knowledge carefully curated by a team of certification experts with 50+ years of combined experience.",
    "Investigate: It is that easy... It's a question away to ask any requirements that are applicable to your product or topic.",
    "Collaborate: Any response can be shared with your colleagues via our built-in email service and it can be downloaded for customization.",
    "Integrate: Action-focused... Any of the checklists can be exported to JIRA so it can be further managed via team resources."
];

export default function Hero() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    const handleButtonClick = (index) => {
        setCurrentIndex(index);
    };

    const boldParts = (text) => {
        const parts = text.split(/(Investigate:|Collaborate:|Integrate:)/);
        return parts.map((part, index) => (
            /Investigate:|Collaborate:|Integrate:/.test(part) ? (
                <span key={index} style={{ fontWeight: 'bold' }}>
                    {part}
                </span>
            ) : (
                part
            )
        ));
    };

    return (
        <Box
            id="hero"
            aria-label="Hero section showcasing the main features and call to action"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 12, sm: 18 },
                    pb: { xs: 2, sm: 4 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                        aria-label="Main title, CertBuddyAI"
                    >
                        CertBuddy
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                fontSize: 'clamp(3.4rem, 10vw, 4rem)',
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                            }}
                        >
                            AI
                        </Typography>
                    </Typography>
                    <Typography
                        textAlign="center"
                        color="text.secondary"
                        transition="3s"
                        sx={{
                            alignSelf: 'center',
                            width: { sm: '100%', md: '80%' },
                            fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.4rem' }
                        }}
                        aria-label={`Description text: ${texts[currentIndex]}`}
                    >
                        {boldParts(texts[currentIndex])}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.1, mb: 2 }}>
                        {texts.map((text, index) => (
                            <Button
                                key={index}
                                onClick={() => handleButtonClick(index)}
                                sx={(theme) => ({
                                    mx: 1,
                                    width: 12,
                                    height: 12,
                                    minWidth: 12,
                                    minHeight: 12,
                                    borderRadius: '50%',
                                    backgroundColor: currentIndex === index ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.3),
                                    transition: 'background-color 0.3s, transform 0.3s, box-shadow 0.3s',
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.5),
                                        transform: 'scale(1.2)',
                                    },
                                })}
                                aria-label={`Navigate to description ${index + 1}`}
                            />
                        ))}
                    </Box>

                    <Typography textAlign="center" color="text.secondary" sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}>
                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            component="a"
                            href="mailto:kannan@certbuddy.net?subject=Certbuddy%20Free%20Trial%20Access"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Click to send an email to CertBuddyAI and request free trial access"
                        >
                            Free Trial Access
                        </Button>
                    </Typography>
                </Stack>
                <Box
                    id="video"
                    aria-label="Video showcasing the product in action"
                    sx={(theme) => ({
                        mt: { xs: 8, sm: 10 },
                        alignSelf: 'center',
                        height: { xs: 300, sm: 500 },
                        width: '100%',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    })}
                >
                    <video
                        autoPlay
                        loop
                        muted
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                        }}
                        aria-label="CertBuddyAI promotional video"
                    >
                        <source src={CertBuddy_Landing_Vid} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>

            </Container>
        </Box>
    );
}
