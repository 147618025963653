
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const userTestimonials = [
    {
        avatar: <Avatar alt="Kannan" src="/static/images/avatar/1.jpg" />,
        name: 'Kannan',
        occupation: 'Founder & CEO',
        testimonial:
            "I am incredibly proud of how versatile CertBuddy is. It seamlessly automates the entire certification process, adapting to the unique needs of every project!",
    },
    {
        avatar: <Avatar alt="Arvind" src="/static/images/avatar/2.jpg" />,
        name: 'Arvind',
        occupation: 'Technical Advisor',
        testimonial:
            "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last, offering reliability and performance that consistently impresses.",
    },
    {
        avatar: <Avatar alt="Seth" src="/static/images/avatar/3.jpg" />,
        name: 'Seth',
        occupation: 'Software Engineer',
        testimonial:
            "I've never experienced technology quite like CertBuddy AI. Its innovative approach to certification management sets a new standard, combining advanced capabilities with user-friendly design.",
    },
];

export default function Testimonials() {

    return (
        <Container
            id="testimonials"
            aria-label="Testimonials section with feedback from our clients"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    Testimonials
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Discover why people love CertBuddyAI. Experience unparalleled efficiency,
                    comprehensive certification automation, and dedicated support that ensure quality
                    and innovation in every step.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {userTestimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                                p: 1,
                            }}
                        >
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {testimonial.testimonial}
                                </Typography>
                            </CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    pr: 2,
                                }}
                            >
                                <CardHeader
                                    avatar={testimonial.avatar}
                                    title={testimonial.name}
                                    subheader={testimonial.occupation}
                                />
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
