
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';


const items = [
    {
        icon: <AutoFixHighRoundedIcon />,
        title: 'Extensive Knowledge Base',
        description:
            'Harness our comprehensive repository of certification data, ensuring informed decisions tailored to your industry and compliance needs.',
    },
    {
        icon: <ConstructionRoundedIcon />,
        title: 'Chatbot Functionality',
        description:
            'Engage our chatbot to receive personalized certification recommendations and guidance, adapting to your specific requirements effortlessly.',
    },
    {
        icon: <ThumbUpAltRoundedIcon />,
        title: 'Great User Experience',
        description:
            'Enjoy a seamless user interface designed for intuitive navigation and streamlined certification management.',
    },
    {
        icon: <SettingsSuggestRoundedIcon />,
        title: 'Efficient Exporting',
        description:
            'Export your certification data into Jira or other management tools, ensuring organized and efficient tracking of compliance tasks.',
    },
    {
        icon: <QueryStatsRoundedIcon />,
        title: 'Dynamic Sidebar',
        description:
            'Navigate diverse certification requirements with ease using our dynamic sidebar, tailored to address specific compliance criteria and preferences.',
    },
    {
        icon: <SupportAgentRoundedIcon />,
        title: 'Reliable Support',
        description:
            'Rely on our dedicated customer support team for responsive assistance, ensuring you receive ongoing guidance beyond initial implementation.',
    },
];


export default function Highlights() {
    return (
        <Box
            id="highlights"
            aria-label="Highlights section emphasizing special aspects of our service"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: '#06090a',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4">
                        Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Discover how CertBuddyAI simplifies certification using the power of AI and human expertise.
                        From adaptability to reliability, experience a product designed for seamless compliance
                        management and innovation.
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800',
                                    background: 'transparent',
                                    backgroundColor: 'grey.900',
                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
