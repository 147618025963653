import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from '../theme/css';

import Iconify from './iconify';
import { varFade } from './animate';

import Simplified_Icons_CertBuddy from '../images/Simplified_Icons_CertBuddy.jpg';

// ----------------------------------------------------------------------

export default function BookNow() {
  const theme = useTheme();

  return (
    <Box
      aria-label="Book Now section for immediate service engagement"
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.grey[900], 0.8),
          imgUrl: Simplified_Icons_CertBuddy,
        }),
        textAlign: 'center',
        color: 'common.white',
        py: { xs: 10, md: 15 },
      }}
    >
      {/* <m.div variants={varFade({ distance: 40 }).inUp}>
        <Typography variant="overline" sx={{ opacity: 0.48 }}>
          Join Us
        </Typography>
      </m.div> */}

      <m.div variants={varFade({ distance: 40 }).inUp}>
        <Typography variant="h2" sx={{ mt: 2, mb: 5 }}>
          Schedule a Free Demo with the Team
        </Typography>
      </m.div>

      <m.div variants={varFade({ distance: 40 }).inUp}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          endIcon={<Iconify icon="carbon:launch" />}
          href="https://calendly.com/certbuddyai/30min?month=2024-07"
          target="_blank"
          rel="noopener"
          aria-label="Book a 30-minute appointment with CertBuddyAI"
        >
          Book Now
        </Button>
      </m.div>
    </Box>
  );
}
